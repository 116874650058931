.sidebar {
  background: linear-gradient(
    45deg,
    rgba(252, 176, 69, 0.3) 25%,
    rgba(15, 41, 34, 1) 100%
  ) !important;
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;
  width: 200px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
