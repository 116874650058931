@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Sourse Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

.pages {
  display: flex;
  position: relative;
  background-image: url("../public/assets/background/chq-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 85%;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
